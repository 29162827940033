import React from 'react';
import { useIntl } from '../../../util/reactIntl';

import css from './Sections.module.css';
import banner from '../../../assets/interested-banner.jpg';
import { ExternalLink, NamedLink } from '../../../components';

function InterestedInBecomingHost() {
  const intl = useIntl();

  const title = intl.formatMessage({
    id: 'InterestedInBecomingHost.title',
  });
  const description1 = intl.formatMessage({
    id: 'InterestedInBecomingHost.description1',
  });
  const description2 = intl.formatMessage({
    id: 'InterestedInBecomingHost.description2',
  });

  const interestedText = intl.formatMessage({
    id: 'InterestedInBecomingHost.interestedText',
  });

  return (
    <div className={css.interestedSection}>
      <div className={css.bannerContent}>
        <div className={css.bannerLeft}>
          <h2>{title}</h2>
          <p>
            {description1}
          </p>
        </div>
        <ExternalLink target="_self" href="/p/become-a-host">
          {interestedText}
        </ExternalLink>
      </div>
    </div>
  );
}

export default InterestedInBecomingHost;
